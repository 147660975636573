import api from '../../services';
import { useSnackbar } from 'notistack';
import 'react-calendar/dist/Calendar.css';
import 'react-date-picker/dist/DatePicker.css';
import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import Table from '../../Components/UI/Table/Table';
import EditOffIcon from '@mui/icons-material/EditOff';
import Button from '../../Components/UI/Button/Button';
import ErrorBoundary from '../../Errors/ErrorBoundary';
import Loader from '../../Components/UI/Loader/Loader';
import PromptSnackbar from '../../Components/UI/Snackbar/PromptSnackbar';

import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import ButtonComp from '../../Components/UI/Button/Button';
import Countires from '../../config/mockData/countries.json';
import TextFieldComp from '../../Components/UI/Input/TextField';

const CountryPrice = () => {
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    countryName: '',
    countryValue: '',
    countryCode: '',
    currencySymbol: '',
    currency: '',
    currencyCode: '',
    basic_monthly: '',
    basic_quarterly: '',
    pro_monthly: '',
    pro_quarterly: '',
  });

  const [country, setCountry] = useState([]);
  const [errors, setErrors] = useState({
    countryName: false,
    countryValue: false,
    countryCode: false,
    currencySymbol: false,
    currency: false,
    currencyCode: false,
    basic_monthly: false,
    basic_quarterly: false,
    pro_monthly: false,
    pro_quarterly: false,
  });
  const [allCountryPrices, setAllCountryPrices] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [dataIsLoading, setDataIsLoading] = useState(false);
  const [countryPriceId, setCountryPriceId] = useState(null);
  const [deleteSubject, setDeleteSubject] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const isTable = true;

  const getAllCountriesPrice = async () => {
    let temp = await api.get.getCountryPrice(isTable);
    setAllCountryPrices(temp);
    setDataIsLoading(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (newState) => {
    setDeleteSubject({ open: true, ...newState });
  };

  const fields = [
    { field: 'id' },
    { field: 'countryName', headerName: 'Country Name', flex: 1 },
    { field: 'currencyCode', headerName: 'Country Code', flex: 1 },
    { field: 'basic_monthly', headerName: 'Basic Monthly Price', flex: 1 },
    { field: 'basic_quarterly', headerName: 'Basic Quarterly Price', flex: 1 },
    { field: 'pro_monthly', headerName: 'Pro Monthly Price', flex: 1 },
    { field: 'pro_quarterly', headerName: 'Pro Quarterly Price', flex: 1 },
    {
      field: 'action',
      headerName: 'Actions',
      renderCell: (params) => (
        <>
          <IconButton
            aria-label='update'
            color='primary'
            onClick={() => {
              setSelectedCategory(params);
              setCountryPriceId(params?.row?.id);
              setData({
                basic_monthly: params?.row?.basic_monthly,
                basic_quarterly: params?.row?.basic_quarterly,
                pro_monthly: params?.row?.pro_monthly,
                pro_quarterly: params?.row?.pro_quarterly,
              });
              setShowForm(true);
            }}
          >
            <EditOffIcon />
          </IconButton>

          <IconButton
            aria-label='delete'
            style={{ color: 'red' }}
            onClick={(e) => {
              handleClick({
                vertical: 'top',
                horizontal: 'center',
              });
              setSelectedCategory(params);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const handleSubmit = () => {
    console.log('submit');
    const newErrors = {
      countryName: !countryPriceId && !data?.countryName,
      countryValue: !countryPriceId && !data?.countryValue,
      countryCode: !countryPriceId && !data?.countryCode,
      currencySymbol: !countryPriceId && !data?.currencySymbol,
      currency: !countryPriceId && !data?.currency,
      currencyCode: !countryPriceId && !data?.currencyCode,
      basic_monthly: !data?.basic_monthly,
      basic_quarterly: !data?.basic_quarterly,
      pro_monthly: !data?.pro_monthly,
      pro_quarterly: !data?.pro_quarterly,
    };

    setErrors(newErrors);

    if (Object.values(newErrors).includes(true)) {
      return;
    }

    addEditCountryPrice(data);
    console.log('submit end');
  };

  // Add or Update the Country Price
  const addEditCountryPrice = async (payload) => {
    setLoading(true);

    const result = await api.post
      .addUpdateCountryPrice(payload, countryPriceId)
      .then((res) => {
        if (res?.data) {
          const { error } = res;

          if (!error) {
            enqueueSnackbar(
              `Country Price ${
                countryPriceId ? 'updated' : 'created'
              } successfully!`,
              {
                variant: 'success',
                autoHideDuration: 3000,
              }
            );

            setLoading(false);
            setShowForm(false);
            setCountry([]);
          } else {
            enqueueSnackbar(
              `OOPS! error occoured while ${
                countryPriceId ? 'updating' : 'creating'
              } Country Price, Please try again`,
              {
                variant: 'error',
                autoHideDuration: 4000,
              }
            );
          }

          getAllCountriesPrice();
        } else {
          enqueueSnackbar(res?.response, {
            variant: 'error',
            autoHideDuration: 4000,
          });

          setLoading(false);
        }
      });
  };

  const deleteTagHandler = async () => {
    const result = await api.delete.deleteCountryPrice(selectedCategory.row.id);
    const { error } = result;
    if (!error) {
      enqueueSnackbar('Country Price deleted successfully!', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    } else {
      enqueueSnackbar(
        'OOPS! error occoured while deleting Country Price, Please try again',
        {
          variant: 'error',
          autoHideDuration: 4000,
        }
      );
    }
    getAllCountriesPrice();
    setDeleteSubject({ ...deleteSubject, open: false });
  };

  const handlePaperRef = (event) => {
    const { value } = event.target;

    setCountry(value);

    const countryName = value?.label;
    const countryValue = value?.value;
    const countryCode = value?.country_code;
    const currencySymbol = value?.currency_symbol;
    const currency = value?.currency_name;
    const currencyCode = value?.currency_code;

    setData((prev) => ({
      ...prev,
      countryName,
      countryValue,
      countryCode,
      currencySymbol,
      currency,
      currencyCode,
    }));

    setErrors((prev) => {
      return {
        ...prev,
        country: false,
      };
    });
  };

  useEffect(() => {
    setDataIsLoading(true);
    getAllCountriesPrice();
  }, []);

  return (
    <React.Fragment>
      <Button
        onClick={(e) => {
          setShowForm(!showForm);
          setData(null);
          setCountryPriceId(null);
        }}
        style={{ width: '100%', marginBottom: '23px' }}
      >
        {showForm ? 'Show Country Price' : 'Add Country Price'}
      </Button>

      {showForm ? (
        <>
          <Box
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}
          >
            {!countryPriceId && (
              <FormControl>
                <InputLabel id='demo-multiple-name-label'>
                  Select Country *
                </InputLabel>

                <Select
                  labelId='demo-multiple-name-label'
                  id='demo-multiple-name'
                  value={countryPriceId ? data?.session : country}
                  onChange={handlePaperRef}
                  input={<OutlinedInput label='Add Paper Reference' />}
                >
                  {dataIsLoading ? (
                    <Loader />
                  ) : (
                    Countires?.map((country, index) => (
                      <MenuItem key={index} value={country}>
                        {country?.label}
                      </MenuItem>
                    ))
                  )}
                </Select>

                {errors?.country && (
                  <Typography
                    variant='small'
                    style={{
                      color: 'red',
                      fontSize: '12px',
                    }}
                  >
                    Country is required
                  </Typography>
                )}
              </FormControl>
            )}

            <FormControl>
              <Typography variant='h6'>Basic Monthly Price *</Typography>

              <TextFieldComp
                type={'number'}
                value={data?.basic_monthly}
                onChange={(e) => {
                  setData((prev) => {
                    return { ...prev, basic_monthly: e.target.value };
                  });

                  if (errors?.basic_monthly) {
                    setErrors((prev) => ({
                      ...prev,
                      basic_monthly: false,
                    }));
                  }
                }}
                label=''
              />

              {errors?.basic_monthly && (
                <Typography
                  variant='small'
                  style={{
                    color: 'red',
                    fontSize: '12px',
                  }}
                >
                  Basic Monthly Price is required
                </Typography>
              )}
            </FormControl>

            <FormControl>
              <Typography variant='h6'>Basic Quarterly Price *</Typography>

              <TextFieldComp
                type={'number'}
                value={data?.basic_quarterly}
                onChange={(e) => {
                  setData((prev) => {
                    return { ...prev, basic_quarterly: e.target.value };
                  });

                  if (errors?.basic_quarterly) {
                    setErrors((prev) => ({
                      ...prev,
                      basic_quarterly: false,
                    }));
                  }
                }}
                label=''
              />

              {errors?.basic_quarterly && (
                <Typography
                  variant='small'
                  style={{
                    color: 'red',
                    fontSize: '12px',
                  }}
                >
                  Basic Quarterly Price is required
                </Typography>
              )}
            </FormControl>

            <FormControl>
              <Typography variant='h6'>Pro Monthly Price *</Typography>

              <TextFieldComp
                type={'number'}
                value={data?.pro_monthly}
                onChange={(e) => {
                  setData((prev) => {
                    return { ...prev, pro_monthly: e.target.value };
                  });

                  if (errors?.pro_monthly) {
                    setErrors((prev) => ({
                      ...prev,
                      pro_monthly: false,
                    }));
                  }
                }}
                label=''
              />

              {errors?.pro_monthly && (
                <Typography
                  variant='small'
                  style={{
                    color: 'red',
                    fontSize: '12px',
                  }}
                >
                  Pro Monthly Price is required
                </Typography>
              )}
            </FormControl>

            <FormControl>
              <Typography variant='h6'>Pro Quarterly Price *</Typography>

              <TextFieldComp
                type={'number'}
                value={data?.pro_quarterly}
                onChange={(e) => {
                  setData((prev) => {
                    return { ...prev, pro_quarterly: e.target.value };
                  });

                  if (errors?.pro_quarterly) {
                    setErrors((prev) => ({
                      ...prev,
                      pro_quarterly: false,
                    }));
                  }
                }}
                label=''
              />

              {errors?.pro_quarterly && (
                <Typography
                  variant='small'
                  style={{
                    color: 'red',
                    fontSize: '12px',
                  }}
                >
                  Pro Quarterly Price is required
                </Typography>
              )}
            </FormControl>

            {loading ? (
              <Loader />
            ) : (
              <ButtonComp onClick={handleSubmit}>
                {countryPriceId ? 'Update' : 'Add'} Country Price
              </ButtonComp>
            )}
          </Box>
        </>
      ) : (
        <>
          <ErrorBoundary>
            {dataIsLoading ? (
              <Loader />
            ) : (
              <Table tableData={allCountryPrices} cols={fields} />
            )}
          </ErrorBoundary>

          <PromptSnackbar
            setState={setDeleteSubject}
            state={deleteSubject}
            clickHandler={deleteTagHandler}
          >
            Do you want to Delete this Country Price
          </PromptSnackbar>
        </>
      )}
    </React.Fragment>
  );
};

export default CountryPrice;
