import baseUrl from './config/baseUrl';

const getSubjects = async () => {
  let token = await localStorage.getItem('auth-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };
  const data = await fetch(`${baseUrl}/subject`, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      result = JSON.parse(result);
      return result;
    })
    .catch((error) => error);
  return data;
};

const getChaptersNotesList = async (selectedId) => {
  const token = await localStorage.getItem('auth-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(
    `${baseUrl}/note/snack-id/${selectedId}?chapter=true`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => {
      result = JSON.parse(result);
      return result;
    })
    .catch((error) => error);
  return data;
};
const getSnackNotes = async (selectedSnackId) => {
  const token = await localStorage.getItem('auth-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(
    `${baseUrl}/note/snack-id/${selectedSnackId}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => {
      result = JSON.parse(result);
      return result;
    })
    .catch((error) => error);
  return data;
};

const allTags = async () => {
  let token = await localStorage.getItem('auth-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };
  const data = await fetch(`${baseUrl}/tag`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
  return data;
};

const addSubjects = async (subject) => {
  let token = await localStorage.getItem('auth-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify({
    title: subject,
  });

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const data = await fetch(`${baseUrl}/subject`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => console.log('error', error));
  return data;
};

const editSubject = async (subjectID, subjectName, image) => {
  let token = await localStorage.getItem('auth-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify({
    title: subjectName,
    image: image,
  });

  const requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const data = await fetch(`${baseUrl}/subject/${subjectID}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);

  return data;
};

const addCategory = async (category) => {
  let token = await localStorage.getItem('auth-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify({
    title: category,
  });

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const data = await fetch(`${baseUrl}/tag`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => console.log('error', error));
  return data;
};

const getCategories = async () => {
  const token = localStorage.getItem('auth-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(`${baseUrl}/tag`, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      result = JSON.parse(result);
      let temp = result.data.map((row) => ({
        ...row,
        category: row.title.charAt(0).toUpperCase() + row.title.slice(1),
      }));
      return temp;
    })
    .catch((error) => console.log('error', error));
  return data;
};

const editCategories = async (categoryID, edittedValue) => {
  let token = await localStorage.getItem('auth-token');
  let myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');
  let raw = JSON.stringify({
    title: edittedValue,
  });

  let requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const data = await fetch(`${baseUrl}/tag/${categoryID}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => console.log('error', error));
  return data;
};

// -------------------- Simulation Services Start ---------------------

const createSimulation = async (payload, simulationId) => {
  const token = localStorage.getItem('auth-token');

  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify({
    data: payload,
  });

  const requestOptions = {
    method: simulationId ? 'PUT' : 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  // If simulation id exist then it will update the simulation

  // If simulation id does not exist it will create the new simulatin

  const endPoint = simulationId
    ? `${baseUrl}/simulation/${simulationId}`
    : `${baseUrl}/simulation`;

  const result = await fetch(endPoint, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => console.log('error', error));
  return result;
};

const getSimulations = async ({ snackId }) => {
  const token = localStorage.getItem('auth-token');

  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const result = await fetch(`${baseUrl}/simulation/${snackId}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => console.log('Error >>> ', error));

  return result?.data;
};

const deleteSimulation = async (simulationId) => {
  let token = localStorage.getItem('auth-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(
    `${baseUrl}/simulation/${simulationId}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => console.log('error', error));
  return data;
};

// -------------------- Simulation Services End ---------------------

// -------------------- Country Price Services Start ---------------------

const addUpdateCountryPrice = async (payload, id) => {
  let token = localStorage.getItem('auth-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify({
    payload,
  });

  const requestOptions = {
    method: id ? 'PUT' : 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const endPoint = id
    ? `${baseUrl}/countryPrice/${id}`
    : `${baseUrl}/countryPrice`;

  const data = await fetch(endPoint, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => console.log('error', error));
  return data;
};

const getCountryPrice = async (isTable) => {
  const token = localStorage.getItem('auth-token');

  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(`${baseUrl}/countryPrice`, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      result = JSON.parse(result);
      if (isTable) {
        let temp = result?.data?.map((row) => ({
          ...row,
          value: {
            id: row?.id,
            countryName: row?.countryName,
            currencyCode: row?.currencyCode,
            basic_monthly: row?.basic_monthly,
            basic_quarterly: row?.basic_quarterly,
            pro_monthly: row?.pro_monthly,
            pro_quarterly: row?.pro_quarterly,
          },
        }));
        return temp;
      } else {
        return result?.data;
      }
    })
    .catch((error) => console.log('error', error));
  return data;
};

const deleteCountryPrice = async (id) => {
  let token = localStorage.getItem('auth-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(`${baseUrl}/countryPrice/${id}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => console.log('error', error));
  return data;
};

// -------------------- Country Price Services End ---------------------

// -------------------- Past Paper Reference Services Start  ---------------------

const addPaperReference = async (payload, id) => {
  let token = localStorage.getItem('auth-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify({
    payload,
  });

  const requestOptions = {
    method: id ? 'PUT' : 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const endPoint = id
    ? `${baseUrl}/pastPaperReference/${id}`
    : `${baseUrl}/pastPaperReference`;

  const data = await fetch(endPoint, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => console.log('error', error));
  return data;
};

const getPaperReference = async (isTable) => {
  const token = localStorage.getItem('auth-token');

  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(`${baseUrl}/pastPaperReference`, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      result = JSON.parse(result);
      if (isTable) {
        let temp = result?.data?.map((row) => ({
          ...row,
          value: {
            session: row?.session,
            year: row?.year,
          },
        }));
        return temp;
      } else {
        return result?.data;
      }
    })
    .catch((error) => console.log('error', error));
  return data;
};

const deletePaperReference = async (id) => {
  let token = localStorage.getItem('auth-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(
    `${baseUrl}/pastPaperReference/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => console.log('error', error));
  return data;
};

// -------------------- Past Paper Reference Services End ---------------------

// -------------------- Quiz Question Services Start -------------------

const createQuizQuestion = async (payload, quizQuestionId) => {
  const token = localStorage.getItem('auth-token');

  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify({
    data: payload,
  });

  const requestOptions = {
    method: quizQuestionId ? 'PUT' : 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const endPoint = quizQuestionId
    ? `${baseUrl}/quizQuestion/${quizQuestionId}`
    : `${baseUrl}/quizQuestion`;

  const result = await fetch(endPoint, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => console.log('error', error));
  return result;
};

const createBulkQuestions = async (payload) => {
  console.log('payload: inside', payload);

  const token = localStorage.getItem('auth-token');

  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify({
    data: payload,
  });

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const endPoint = `${baseUrl}/quizQuestion/bulk`;

  const result = await fetch(endPoint, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => console.log('error', error));
  return result;
};

const getQuizQuestions = async ({ isTable, snackId }) => {
  const token = localStorage.getItem('auth-token');

  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(`${baseUrl}/quizQuestion/${snackId}`, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      result = JSON.parse(result);
      if (isTable) {
        let temp = result?.data?.map((row) => ({
          ...row,
          value: [
            { text: row?.text?.charAt(0)?.toUpperCase() + row?.text?.slice(1) },
            { correctAnswer: row?.correctAnswer },
            { publicKey: row?.publicKey },
            {
              pastPaperReferenceText: row?.pastPaperReferenceText,
            },
          ],
        }));
        return temp;
      } else {
        return result?.data;
      }
    })
    .catch((error) => console.log('error', error));
  return data;
};

const deleteQuestion = async (id) => {
  let token = localStorage.getItem('auth-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(`${baseUrl}/quizQuestion/${id}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => console.log('error', error));
  return data;
};

// -------------------- Quiz Question Services End ---------------------

const updateChapterNotes = async (e, selectedChapterId, tagIdState) => {
  let token = await localStorage.getItem('auth-token');
  let myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');
  let raw = {
    body: JSON.stringify(e),
    chapterId: selectedChapterId,
    tagId: tagIdState,
  };

  let requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: JSON.stringify(raw),
    redirect: 'follow',
  };

  const data = await fetch(
    `${baseUrl}/note/${selectedChapterId}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => console.log('error', error));
  return data;
};
const updateSnackNotes = async ({
  e,
  selectedSnackId,
  tagIdState,
  currentNoteId,
}) => {
  let token = await localStorage.getItem('auth-token');
  let myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');
  let raw = {
    body: JSON.stringify(e),
    snackId: selectedSnackId,
    tagId: tagIdState,
  };

  let requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: JSON.stringify(raw),
    redirect: 'follow',
  };

  const data = await fetch(`${baseUrl}/note/${currentNoteId}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => console.log('error', error));
  return data;
};

const publishedApiHadler = async (previousCourseId) => {
  let token = await localStorage.getItem('auth-token');
  let myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  let requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(
    `${baseUrl}/course/${previousCourseId}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => console.log('error', error));
  return data;
};
const deleteCategories = async (categoryID) => {
  let token = await localStorage.getItem('auth-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(`${baseUrl}/tag/${categoryID}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => console.log('error', error));
  return data;
};

const deleteNote = async (currentNotedId) => {
  let token = await localStorage.getItem('auth-token');
  let myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  let requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow',
  };
  const data = await fetch(`${baseUrl}/note/${currentNotedId}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => console.log('error', error));
  return data;
};
const deleteFlashCard = async (currentFlashCardId) => {
  let token = await localStorage.getItem('auth-token');
  let myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  let requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow',
  };
  const data = await fetch(
    `${baseUrl}/flashcard/${currentFlashCardId}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => console.log('error', error));
  return data;
};

const editLevel = async (levelID, edittedValue) => {
  let token = await localStorage.getItem('auth-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify({
    title: edittedValue.title,
    bot: edittedValue.bot,
  });

  const requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const data = await fetch(`${baseUrl}/grade-level/${levelID}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);

  return data;
};

const addLevel = async (level) => {
  let token = await localStorage.getItem('auth-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify({
    title: level.levelName,
    bot: level.bot,
  });

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const data = await fetch(`${baseUrl}/grade-level`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
  return data;
};

const addLearningObjectivesToChapter = async (
  learningObjective,
  chapterId,
  courseId
) => {
  let token = await localStorage.getItem('auth-token');
  let learningObjectiveValues = {
    title: JSON.stringify(learningObjective),
    chapterId,
    courseId,
  };

  let requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(learningObjectiveValues),
    redirect: 'follow',
  };
  let textEditorURL = `${baseUrl}/lo`;
  const data = await fetch(textEditorURL, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
  return data;
};

const addNoteswithChapters = async ({
  e,
  selectedChapterId,
  tagIdState,
  courseId,
  noteDetails,
  tagName,
}) => {
  let token = await localStorage.getItem('auth-token');
  let desciptionNotesValues = {
    body: JSON.stringify(e),
    chapterId: selectedChapterId,
    tagId: tagIdState,
    courseId,
    unitId: noteDetails.unitId,
    unitName: noteDetails.unitName,
    chapterName: noteDetails.chapterName,
    snackName: noteDetails?.snackName,
    tagName,
  };
  let requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(desciptionNotesValues),
    redirect: 'follow',
  };
  let descriptionNotesURL = `${baseUrl}/note`;
  const descriptionNotesURLdata = await fetch(
    descriptionNotesURL,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
  return descriptionNotesURLdata;
};

const addNoteswithSnacks = async ({
  e,
  selectedSnackId,
  tagIdState,
  courseId,
  noteDetails,
  tagName,
}) => {
  let token = await localStorage.getItem('auth-token');
  let desciptionNotesValues = {
    body: JSON.stringify(e),
    snackId: selectedSnackId,
    tagId: tagIdState,
    courseId,
    unitId: noteDetails.unitId,
    unitName: noteDetails.unitName,
    chapterName: noteDetails.chapterName,
    snackName: noteDetails?.snackName,
    tagName,
  };
  let requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(desciptionNotesValues),
    redirect: 'follow',
  };
  let descriptionNotesURL = `${baseUrl}/note`;
  const descriptionNotesURLdata = await fetch(
    descriptionNotesURL,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
  return descriptionNotesURLdata;
};

const addFlashCardWithChapter = async ({
  e,
  selectedChapterId,
  tagIdState,
}) => {
  let token = await localStorage.getItem('auth-token');
  const { question, answer } = e;
  let desciptionNotesValues = {
    chapterId: selectedChapterId,
    tagId: tagIdState,
    question: JSON.stringify(question),
    answer: JSON.stringify(answer),
  };
  let requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(desciptionNotesValues),
    redirect: 'follow',
  };
  let descriptionNotesURL = `${baseUrl}/flashcard`;
  const chaptersFlashCard = await fetch(descriptionNotesURL, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
  return chaptersFlashCard;
};

const addFlashCardWithSnacks = async ({ e, selectedSnackId, tagIdState }) => {
  let token = await localStorage.getItem('auth-token');
  const { question, answer, order } = e;
  let desciptionNotesValues = {
    snackId: selectedSnackId,
    tagId: tagIdState,
    order: order,
    question: JSON.stringify(question),
    answer: JSON.stringify(answer),
  };
  let requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(desciptionNotesValues),
    redirect: 'follow',
  };
  let descriptionNotesURL = `${baseUrl}/flashcard`;
  const snacksFlashCard = await fetch(descriptionNotesURL, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
  return snacksFlashCard;
};

const getLearningObjectivesFromChapter = async (chapterId) => {
  let token = await localStorage.getItem('auth-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(
    `${baseUrl}/lo/chapterId/${chapterId}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);

  return data;
};
const getLearningObjectivesById = async (learningObjectiveId) => {
  let token = await localStorage.getItem('auth-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(
    `${baseUrl}/lo/${learningObjectiveId}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);

  return data;
};

const getUnits = async (courseID) => {
  let token = await localStorage.getItem('auth-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(`${baseUrl}/unit/${courseID}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const getChapterDetails = async (previousCourseId) => {
  let token = await localStorage.getItem('auth-token');
  let requestOptions = {
    method: 'GET',
    redirect: 'follow',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  console.log('previousCourseId', previousCourseId);
  let getChaptersURL = `${baseUrl}/course/${previousCourseId}`;
  const data = await fetch(getChaptersURL, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
  return data;
};
const getChapterDetailsWithCourseId = async (previousCourseId) => {
  let token = await localStorage.getItem('auth-token');
  let requestOptions = {
    method: 'GET',
    redirect: 'follow',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let getChaptersURL = `${baseUrl}/chapter/chapterbycourseid/${previousCourseId}`;
  const data = await fetch(getChaptersURL, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
  return data;
};
const getUserData = async () => {
  const token = await localStorage.getItem('auth-token');

  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(`${baseUrl}/system-user/list`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const deleteChapter = async (chapterID) => {
  let token = await localStorage.getItem('auth-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(`${baseUrl}/chapter/${chapterID}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const getLevel = async () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  const data = await fetch(`${baseUrl}/grade-level`, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      result = JSON.parse(result);
      return result;
    })
    .catch((error) => error);
  return data;
};

const addUnitToCourse = async (unitValue) => {
  let token = await localStorage.getItem('auth-token');
  let requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(unitValue),
    redirect: 'follow',
  };
  let URL = `${baseUrl}/unit`;
  const data = await fetch(URL, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
  return data;
};

const deleteGivenCourse = async (courseID) => {
  let token = await localStorage.getItem('auth-token');

  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(`${baseUrl}/course/${courseID}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
  return data;
};

const createCourseStageOne = async (courseValues) => {
  console.log('courseValues: ', courseValues);
  let token = await localStorage.getItem('auth-token');
  let URL = `${baseUrl}/course`;
  const data = await fetch(URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(courseValues),
  })
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);

  return data;
};

const getCoursesList = async () => {
  let token = await localStorage.getItem('auth-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(`${baseUrl}/course`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);

  return data;
};

const getSingleCourseDetails = async (subjectID, levelID) => {
  let data = await getCoursesList().then((el) => {
    const found = el.data.rows.find(
      (each) =>
        subjectID.id === each.subject.id && each.gradeLevel.id === levelID.id
    );
    return found;
  });
  return data;
};

const addChapterToUnit = async (chapterValues) => {
  let token = await localStorage.getItem('auth-token');
  let requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(chapterValues),
    redirect: 'follow',
  };

  let chapterURL = `${baseUrl}/chapter`;
  const data = await fetch(chapterURL, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
  return data;
};

const getChapterFromUnit = async (unitID) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  const data = await fetch(`${baseUrl}/chapter/${unitID}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const getFlashcards = async (id, type) => {
  let token = localStorage.getItem('auth-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };
  let url = `${baseUrl}/flashcard/${id}`;

  if (type === 'chapter') {
    // url = url + "?chapter=true";
    url = url + '?resource=chapter';
  } else if (type === 'snack') {
    url = url + '?resource=snack';
  }
  let data = await fetch(url, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => JSON.parse(error));
  return data;
};
const getFlashcardsByTagAndChapter = async (ids) => {
  const { chapterId, tagId } = ids;
  let token = localStorage.getItem('auth-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };
  let url = `${baseUrl}/flashcard/content?chapterId=${chapterId}&tagId=${tagId}`;

  // if (type === "chapter") {
  //   // url = url + "?chapter=true";
  //   url = url + "?resource=chapter";
  // } else if (type === "snack") {
  //   url = url + "?resource=snack";
  // }
  let data = await fetch(url, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => JSON.parse(error));
  return data;
};
const updateAllFlahCardsOrder = async (payload) => {
  let token = await localStorage.getItem('auth-token');

  let requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
    redirect: 'follow',
  };

  let url = `${baseUrl}/flashcard/updateAllOrder`;
  const data = await fetch(url, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
  return data;
};

const addChapterToCourse = async (title, courseId) => {
  let token = await localStorage.getItem('auth-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify({
    title,
    courseId,
  });

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const data = await fetch(`${baseUrl}/chapter`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const getNotes = async (payload) => {
  let data;
  if (payload.type === 'chapter') {
    data = await getNotesAgainstChapter(payload.id, payload.type)
      .then((el) => el)
      .catch((err) => err);
  }
  if (payload.type === 'snack') {
    data = await getNotesAgainstSnack(payload.id, payload.type)
      .then((el) => el)
      .catch((err) => err);
  }
  return data;
};
const getNotesAgainstChapter = async (chapterId, type) => {
  let token = await localStorage.getItem('auth-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(
    `${baseUrl}/note/snack-id/${chapterId}?resource=${type}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const getNotesAgainstSnack = async (snackId, type) => {
  let token = await localStorage.getItem('auth-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(
    `${baseUrl}/note/snack-id/${snackId}?resource=${type}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};
const getNotesByCourseAndTag = async (snackId, tagId, type) => {
  let token = await localStorage.getItem('auth-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  if (type === 'snack') {
    const data = await fetch(
      `${baseUrl}/tag/contentbysnack?snackId=${snackId}&tagId=${tagId}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => JSON.parse(result))
      .catch((error) => error);
    return data;
  } else {
    const data = await fetch(
      `${baseUrl}/tag/contentbychapter?chapterId=${snackId}&tagId=${tagId}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => JSON.parse(result))
      .catch((error) => error);
    return data;
  }
};

const deleteSubject = async (subjectID) => {
  let token = await localStorage.getItem('auth-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(`${baseUrl}/subject/${subjectID}`, requestOptions)
    .then((response) => response.json())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const deleteLevel = async (subjectID) => {
  let token = await localStorage.getItem('auth-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(
    `${baseUrl}/grade-level//${subjectID}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
  return data;
};

const updateUserProfile = async (payload) => {
  const token = await localStorage.getItem('auth-token');
  let URL = `${baseUrl}/user`;
  const data = await fetch(URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);

  return data;
};

const deleteUser = async (userId, token) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(`${baseUrl}/system-user/${userId}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const getReferredUsers = async (refCode, token) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(
    `${baseUrl}/system-user/list/referred-users/${refCode}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const deleteUnit = async (unitId) => {
  let token = await localStorage.getItem('auth-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(`${baseUrl}/unit/${unitId}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const addSnacksToChapter = async (snackValues) => {
  let token = await localStorage.getItem('auth-token');

  let requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(snackValues),
    redirect: 'follow',
  };

  let url = `${baseUrl}/snack`;
  const data = await fetch(url, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
  return data;
};

const deleteSnack = async (snackId) => {
  let token = await localStorage.getItem('auth-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(`${baseUrl}/snack/${snackId}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const deleteLearningObjective = async (learningObjectiveId) => {
  let token = await localStorage.getItem('auth-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(
    `${baseUrl}/lo/${learningObjectiveId}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const editChapter = async (chapterID, chapterName) => {
  let token = await localStorage.getItem('auth-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify({
    title: chapterName,
  });

  const requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const data = await fetch(
    `${baseUrl}/chapter/${chapterID}?course=true`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => console.log('error', error));

  return data;
};

const updateLearningObjective = async (updates, learningObjId) => {
  let token = await localStorage.getItem('auth-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify({
    title: updates,
  });

  const requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  let data = await fetch(`${baseUrl}/lo/${learningObjId}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const editSnack = async (snackID, snackName) => {
  let token = await localStorage.getItem('auth-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify({
    title: snackName,
  });

  const requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const data = await fetch(`${baseUrl}/snack/${snackID}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => console.log('error', error));

  return data;
};

const editUnit = async (unitID, unitName) => {
  let token = await localStorage.getItem('auth-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify({
    title: unitName,
  });

  const requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const data = await fetch(`${baseUrl}/unit/${unitID}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => console.log('error', error));

  return data;
};

const editFlashCard = async (flashCardID, payload) => {
  let token = await localStorage.getItem('auth-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify(payload);

  const requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const data = await fetch(
    `${baseUrl}/flashcard/${flashCardID}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => console.log('error', error));

  return data;
};
const api = {
  post: {
    addCategory,
    addSubjects,
    addLevel,
    addLearningObjectivesToChapter,
    createSimulation,
    createQuizQuestion,
    updateAllFlahCardsOrder,
    createBulkQuestions,
    addPaperReference,
    addUpdateCountryPrice,
    createCourse: {
      createCourseStageOne,
      addUnitToCourse,
      addChapterToUnit,
      addNoteswithChapters,
      addNoteswithSnacks,
      addFlashCardWithChapter,
      addFlashCardWithSnacks,
      addChapterToCourse,
      addSnacksToChapter,
    },
  },
  get: {
    getChapterFromUnit,
    getPaperReference,
    getCategories,
    getSubjects,
    getLevel,
    getQuizQuestions,
    getCoursesList,
    getSingleCourseDetails,
    getLearningObjectivesFromChapter,
    getUnits,
    getChapterDetails,
    getChapterDetailsWithCourseId,
    allTags,
    getChaptersNotesList,
    getSnackNotes,
    getFlashcards,
    getFlashcardsByTagAndChapter,
    getNotes,
    getUserData,
    getLearningObjectivesById,
    getNotesByCourseAndTag,
    getSimulations,
    getReferredUsers,
    getCountryPrice,
  },
  put: {
    editCategories,
    editSubject,
    editLevel,
    updateChapterNotes,
    updateSnackNotes,
    publishedApiHadler,
    updateUserProfile,
    editChapter,
    editSnack,
    editUnit,
    updateLearningObjective,
    editFlashCard,
  },
  delete: {
    deleteCategories,
    deletePaperReference,
    deleteCountryPrice,
    deleteGivenCourse,
    deleteChapter,
    deleteSubject,
    deleteUnit,
    deleteUser,
    deleteNote,
    deleteFlashCard,
    deleteSnack,
    deleteLearningObjective,
    deleteLevel,
    deleteSimulation,
    deleteQuestion,
    // deleteCategory
  },
};
export default api;
